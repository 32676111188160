import {useEffect} from "react";
import { List, Datagrid, ShowButton, TextField, Show, NumberField, Create, SimpleForm, useSafeSetState, SimpleFormIterator,
         SimpleShowLayout, required, TextInput, useTranslate, useRedirect, useDataProvider, email, ArrayInput,
         ReferenceField,
         DeleteWithConfirmButton,
         NullableBooleanInput,
         useGetOne,
         BooleanField,
         FunctionField,
} from 'react-admin'
import { PaginationDefault, defaultSort } from "../components/utils";
import {PermIdentity} from '@mui/icons-material';
import FilterTextInput from "../components/filter_textinput";
import { TopToolbarWithCreateButton } from "../components/top_toolbars";
import SelectLang from '../components/select_lang';
import TranslatedTextField from '../components/translated_textfield';
import { Divider, Box, Container } from "@mui/material";
import Loading from "../components/loading";
import { useWatch } from 'react-hook-form';
import CSVFileInputParticipants from "../components/csv_file_input_participants";
import _ from "lodash";
import SelectTypeOfVisitor from "../components/select_type_of_visitor";
import SelectEvents from "../components/select_event";
import OrgTextField from "../components/org_textfield";
import UpdateResourceAction from "../components/update_resource_action";
export const ParticipantIcon = PermIdentity;

const participantFilters = [
  <SelectEvents source="eventIdEq" alwaysOn />,
  <FilterTextInput source="emailAddressLike" alwaysOn/>,
  <FilterTextInput source="lastNameLike" alwaysOn />,
  <FilterTextInput source="companyLike" alwaysOn />,
  <NullableBooleanInput source="attendedEq" alwaysOn />,
];

function ParticipantList() {

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={participantFilters}
      filter={{archivedAtIsSet: false}}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbarWithCreateButton />}
    >
      {participantGrid}
    </List>
  );
}

const participantGrid = 
  <Datagrid bulkActionButtons={false}>
    <TextField source='id' />
    <ReferenceField source="orgId" reference="Org" link="show">
      <OrgTextField source="id" />
    </ReferenceField>
    <ReferenceField source="eventId" reference="Event" link="show" label="resources.Event.fields.event" sortable={false}>
      <TextField source="event" />
    </ReferenceField>
    <TextField source="emailAddress" />
    <TextField source="firstName" />
    <TextField source="lastName" />
    <TextField source="company" />
    <TextField source="position" />
    <TranslatedTextField source="typeOfVisitor" translation="resources.Participant.fields.typeOfVisitors" />
    <FunctionField source="attended" render={record => {
      if (record.attended) return <BooleanField source="attended" /> 
      return <UpdateResourceAction resource="Participant" data={{id: record.id, action: "mark_attended"}} /> 
    }}/>
    <ShowButton />
    <DeleteWithConfirmButton redirect={false} />
  </Datagrid>


function ParticipantShow(){
  return (
    <Show actions={null}>
      <SimpleShowLayout>
        <NumberField source='id' />
        <ReferenceField source="orgId" reference="Org" link="show">
          <OrgTextField source="id" />
        </ReferenceField>
        <ReferenceField source="eventId" reference="Event" link="show" label="resources.Event.fields.event" sortable={false}>
          <TextField source="event" />
        </ReferenceField>
        <TextField source="emailAddress" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="company" />
        <TextField source="position" />
        <TextField source="additionalData" />
        <FunctionField source="attended" render={record => {
          if (record.attended) return <BooleanField source="attended" /> 
          return <UpdateResourceAction resource="Participant" data={{id: record.id, action: "mark_attended"}} /> 
        }}/>
        <TranslatedTextField source="typeOfVisitor" translation="resources.Participant.fields.typeOfVisitors" />
        <TranslatedTextField source="lang" translation="resources.Participant.fields.langs" />
      </SimpleShowLayout>
    </Show>
  );
}

const availableLangs = values => {
  let langs = [];
  values.emailTitleEs && values.emailContentEs && langs.push("ES");
  values.emailTitleEn && values.emailContentEn && langs.push("EN");
  values.emailTitleCa && values.emailContentCa && langs.push("CA");
  values.emailTitleEu && values.emailContentEu && langs.push("EU");
  values.emailTitleFr && values.emailContentFr && langs.push("FR");
  values.emailTitleIt && values.emailContentIt && langs.push("IT");
  values.emailTitleGl && values.emailContentGl && langs.push("GL");
  values.emailTitlePt && values.emailContentPt && langs.push("PT");
  return langs;
}


const ParticipantCreate = () => {
  const [loading, setLoading] = useSafeSetState(false);
  const [watchEventId, setWatchEventId] = useSafeSetState(false);
  const [langs, setLangs] = useSafeSetState([]);
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const {data: event, isLoading} = useGetOne('Event', { id: watchEventId });

  useEffect(() => {
    !isLoading && setLangs(availableLangs(event));
  }, [event]);

  const onSubmit = async valuesArray => {
    const promises = valuesArray.participant.map(v => {
      let { emailAddress, firstName, lastName, company, position, typeOfVisitor, additionalData, lang } = v;
      return dataProvider.create("Participant", {
        data: { eventId: valuesArray.eventId, emailAddress, firstName, lastName, company, position, typeOfVisitor, additionalData, lang }
      });
    });
  
    await Promise.all(promises);
  
    redirect("/Participant");
  }

  const mutationFunction = async valuesArray => {
    const promises = valuesArray.map(v => {
      let { emailAddress, firstName, lastName, company, position, typeOfVisitor, additionalData, lang } = v;
      let finalLang = transformLang(lang);
      let finalTypeOfVisitor = transformTypeOfVisitor(typeOfVisitor);
      return dataProvider.create("Participant", {
        data: { eventId: watchEventId, emailAddress, firstName, lastName, company, position, typeOfVisitor: finalTypeOfVisitor, additionalData, lang: finalLang }
      });
    });
  
    await Promise.all(promises);
   
    redirect("/Participant");
  }

  const WatchValues = () => {
    const eventId = useWatch({ name: 'eventId' });
    eventId !== watchEventId && setWatchEventId(eventId);
    return null;
  };

  if (loading) return <Loading />

  return (
    <Create resource="AttributeList" redirect="show">
      <SimpleForm onSubmit={onSubmit}>
        <SelectEvents source="eventId" validate={required()} />
        <WatchValues />
        <Container>
          <Divider>{translate("resources.Participant.uploadFromFile")}</Divider> 
          <Box display="flex" flexDirection="row" justifyContent="space-evenly" margin="1.5em">
            <CSVFileInputParticipants
              id="upload-values"
              setLoading={setLoading}
              buttonLabel="resources.Participant.createByValues"
              disabled={!watchEventId}
              mutationFunction={mutationFunction}
            />
          </Box>
          <Divider>{translate("resources.Participant.selectManually")}</Divider>
        
          <ArrayInput source="participant" validate={required()}>
            <SimpleFormIterator fullWidth disableReordering inline>
              <TextInput source="emailAddress" validate={[required(), email()]} />
              <TextInput source="firstName" validate={required()}/>
              <TextInput source="lastName"  validate={required()}/>
              <TextInput source="company" validate={required()} />
              <TextInput source="position" validate={required()} />
              <SelectTypeOfVisitor source="typeOfVisitor" />
              <TextInput source="additionalData" validate={required()} autoComplete="off" />
              <SelectLang source="lang" availableLangs={langs} />
            </SimpleFormIterator>
          </ArrayInput>
        </Container>
      </SimpleForm>
    </Create>
  );
}


const transformLang = lang => {
  switch (_.toUpper(lang)) {
    case 'ES':
    case 'ESPAÑOL':
    case 'SPANISH':
      return 'ES';
    case 'CA':
    case 'CATALAN':
    case 'CATALÀ':
      return 'CA';
    case 'EU':
    case 'EUSKERA':
    case 'BASQUE':
      return 'EU';
    case 'FR':
    case 'FRANCÉS':
    case 'FRENCH':
      return 'FR';
    case 'IT':
    case 'ITALIANO':
    case 'ITALIAN':
      return 'IT';
    case 'GL':
    case 'GALLEGO':
    case 'GALICIAN':
      return 'GL';
    case 'PT':
    case 'PORTUGUÉS':
    case 'PORTUGUESE':
      return 'PT';
    default:
      return 'EN';
  }
}


const transformTypeOfVisitor = lang => {
  return _.toUpper(lang);
}


export {ParticipantList, ParticipantShow, ParticipantCreate, participantGrid, participantFilters};
