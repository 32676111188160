import { useEffect } from "react";
import {
  List, Datagrid, ShowButton, EditButton, TextField, TextInput, FunctionField,
  Show, NumberField, Edit, SimpleForm, TopToolbar, Tab, TabbedShowLayout, FilterForm,
  Button, ReferenceManyField, Create, required, email,
  useSafeSetState, ListContextProvider, useRedirect, useTranslate, useNotify, useDataProvider, SimpleShowLayout
} from 'react-admin'
import { Button as MuiButton } from '@mui/material';
import { PaginationDefault, defaultSort, downloadCSV } from "../components/utils";
import {OnlySaveToolbar} from "../components/bottom_toolbars";
import { useParams } from 'react-router-dom';
import {
  Delete, DriveFileMove, Download
} from '@mui/icons-material';
import FilterTextInput from "../components/filter_textinput";
import ParsedDateTextField from "../components/parsed_date_textfield";
import { personGrid } from "./person";
import { TopToolbarWithCreateButton } from "../components/top_toolbars";
import SelectLang from "../components/select_lang";
import { useLocation } from 'react-router-dom';
import _ from "lodash";
import { eventGrid } from "./event";
import { eventFilters } from "./event";
import SelectEvents from "../components/select_event";


function OrgList() {
  const userFilters = [
    <FilterTextInput source="publicNameLike" alwaysOn />,
    <FilterTextInput source="idEq" />,
  ];

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={userFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbarWithCreateButton/>}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source='id' />
        <TextField source='publicName' />
        <TextField source='clientId' sortable={false} />
        <TextField source="adminEmail" sortable={false} />
        <ParsedDateTextField source="createdAt" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
}


function OrgShow(){
  const { id } = useParams();
  const redirect = useRedirect();
  const [eventFilter, setEventFilter] = useSafeSetState({});
  const dataProvider = useDataProvider();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.match(/\/show$/) || location.pathname.match(/\/show#$/)) {
      redirect(`${location.pathname}/details`);
    } 
  }, [location]);

  const ListActionsRedirectAutocomplete = () => {
    return (
      <TopToolbar>
        {id && 
          <>
           
            <EditButton />
          </>
        }
      </TopToolbar>
    )
  };

  return (
    <Show actions={<ListActionsRedirectAutocomplete />} >
      <TabbedShowLayout syncWithLocation={true}>
        <Tab label="resources.actions.details" path="details">
          <NumberField source='id' />
          <TextField source="adminFullName" />
          <TextField source='clientId' /> 
          <TextField source='publicName' />
          <TextField source="adminEmail" />
          <TextField source='logoUrl' />
          <TextField source="backgroundColor" />
          <TextField source="foregroundColor" />
          <TextField source="footerHtml" />
          <ParsedDateTextField source="createdAt" />

          <FunctionField
            render={() => { 
              if (!id) return;
              return <Button 
                href={`#/OrgDeletion/create?source={"orgId":"${id}"}`}
                label="resources.actions.requestOrgDeletion">
                <Delete/>
              </Button>;
            }}
          /> 
        </Tab>
        <Tab label="resources.Person.many" path="persons">
          <div className="nested-resource" >
            <ReferenceManyField reference="Person" target="orgIdEq" label=""
              perPage={20}
              pagination={<PaginationDefault />}
              filter={{archivedAtIsSet: false}}
            >
              {personGrid}
            </ReferenceManyField>
          </div>
        </Tab>
        <Tab label="resources.Event.many" path="events">
          <div className="nested-resource">
            <ReferenceManyField
                  reference="Event"
                  target="orgIdEq"
                  label=""
                  sort={defaultSort}
                  filter={{ ...eventFilter }}
                  perPage={20}
                  pagination={<PaginationDefault />}
              >
                <FilterForm
                  filters={eventFilters}
                  onChange={(e: any) => { setEventFilter(f => ({...f, [e.target.name]: e.target.value}))}}
                />
                {eventGrid}
            </ReferenceManyField>
          </div>
      </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

const OrgCreate = () => {
  return (
    <Create title='resources.Org.create' resource="Org" redirect="show">
        <SimpleForm warnWhenUnsavedChanges>
          <TextInput source="emailAddress" validate={[required(), email()]}  autoComplete="off"/>
          <TextInput source="fullName" validate={[required()]} autoComplete="off" />
          <SelectLang source="lang" />
          <TextInput source="clientId" validate={[required()]} autoComplete="off" />
          <TextInput source="publicName" validate={[required()]} autoComplete="off" />
          <TextInput source="logoUrl" autoComplete="off" />
          <TextInput source="backgroundColor" autoComplete="off" />
          <TextInput source="foregroundColor" autoComplete="off" />
          <TextInput source="footerHtml" fullWidth multiline minRows={5} autoComplete="off" />
        </SimpleForm>
    </Create>
  );
}


const OrgTitle = ({ record }: {record?: any}) => {
  return <span>Org {record ? `"${record.publicName || record.id}"` : ''}</span>;
};

const OrgEdit = () => {
  return (
    <Edit title={<OrgTitle />} redirect="show">
      <SimpleForm toolbar={<OnlySaveToolbar />} warnWhenUnsavedChanges>
        <TextInput source="clientId" validate={[required()]} autoComplete="off" />
        <TextInput source="publicName" validate={[required()]} autoComplete="off" />
        <TextInput source="logoUrl" autoComplete="off" />
        <TextInput source="foregroundColor" autoComplete="off" />
        <TextInput source="backgroundColor" autoComplete="off" />
        <TextInput source="footerHtml" fullWidth multiline minRows={5} autoComplete="off" />
      </SimpleForm>
    </Edit>
  )
};

export {OrgList, OrgShow, OrgCreate, OrgEdit};
