import { List, Datagrid, ShowButton, TextField, Show, SimpleShowLayout, ReferenceField, FunctionField, BooleanInput, BooleanField } from 'react-admin'
import { PaginationDefault, defaultSort } from "../components/utils";
import {PermIdentity} from '@mui/icons-material';
import ParsedDateTextField from "../components/parsed_date_textfield";
import { TopToolbarDefault } from "../components/top_toolbars";
import OrgTextField from '../components/org_textfield';
import SelectOrgs from '../components/select_orgs';
import { NullableBooleanInput } from 'react-admin';
import SelectEvents from '../components/select_event';
export const PersonIcon = PermIdentity;


const outgoingEmailMessageFilters = [
  <NullableBooleanInput source="sentAtIsSet" alwaysOn />,
];

function OutgoingEmailMessageList() {

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={outgoingEmailMessageFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbarDefault />}
    >
      {outgoingEmailMessageGrid}
    </List>
  );
}

const outgoingEmailMessageGrid = 
  <Datagrid bulkActionButtons={false}>
    <TextField source='id' />
    <ReferenceField source="orgId" reference="Org" link="show">
      <OrgTextField source="id" />
    </ReferenceField>
    <ReferenceField source="eventId" reference="Event" link="show" label="resources.Event.fields.event" sortable={false}>
      <TextField source="event" />
    </ReferenceField>
    <ReferenceField source="participantId" reference="Participant" link="show" label="resources.Participant.fields.emailAddress" sortable={false}>
      <TextField source="emailAddress" />
    </ReferenceField>
    <ReferenceField source="participantId" reference="Participant" link="show" label="resources.Participant.fields.firstName" sortable={false}>
      <TextField source="firstName" />
    </ReferenceField>
    <ReferenceField source="participantId" reference="Participant" link="show" label="resources.Participant.fields.lastName" sortable={false}>
      <TextField source="lastName" />
    </ReferenceField>
    <ParsedDateTextField source='sentAt' />
    <ShowButton />
  </Datagrid>


function OutgoingEmailMessageShow(){
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source='id' />
        <ReferenceField source="orgId" reference="Org" link="show">
          <OrgTextField source="id" />
        </ReferenceField>
        <ReferenceField source="eventId" reference="Event" link="show" label="resources.Event.fields.event" sortable={false}>
          <TextField source="event" />
        </ReferenceField>
        <ReferenceField source="participantId" reference="Participant" link="show" label="resources.Participant.fields.emailAddress">
          <TextField source="emailAddress" />
        </ReferenceField>
        <ReferenceField source="participantId" reference="Participant" link="show" label="resources.Participant.fields.firstName">
          <TextField source="firstName" />
        </ReferenceField>
        <ReferenceField source="participantId" reference="Participant" link="show" label="resources.Participant.fields.lastName">
          <TextField source="lastName" />
        </ReferenceField>
        <ParsedDateTextField source='sentAt' />
      </SimpleShowLayout>
    </Show>
  );
}


export {OutgoingEmailMessageList, OutgoingEmailMessageShow, outgoingEmailMessageGrid, outgoingEmailMessageFilters};
