import { Confirm, useDataProvider, useSafeSetState, useNotify, useGetRecordId, useRefresh, useTranslate } from 'react-admin';
import { Button } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useParams } from 'react-router-dom';


const UpdateResourceAction = ({resource, data}) => {
  let { id } = useParams();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const [confirmArchiveOpen, setConfirmArchiveOpen] = useSafeSetState(false);
  const handleUpdate = async () => {
    try {
      await dataProvider.update(resource, {
        id, data, previousData: {}
      });
    } catch (e) {
      notify(`resources.${resource}.errorUpdating`, { type: 'error' });
    }
    setConfirmArchiveOpen(false);
    refresh();
  }

  return <>
    <Button
      size="small"
      onClick={(e) => {e.stopPropagation(); setConfirmArchiveOpen(true)}}
      startIcon={<CheckBoxOutlineBlankIcon />}
    >
      {translate(`resources.${resource}.mark_attended.call_to_action`)}
    </Button>
    <Confirm
      isOpen={confirmArchiveOpen}
      title={`resources.${resource}.mark_attended.title`}
      onConfirm={handleUpdate}
      onClose={() => setConfirmArchiveOpen(false)}
      content={`resources.${resource}.mark_attended.content`}
    />
  </>
}

export default UpdateResourceAction;
