export const i18n = {
  en: {
    admin: {
      errors: {
        default: "Error: An error has ocurred",
        invalidPassword: "Invalid password or one-time password",
      },
    },
    resources: {
      Org: {
        name: 'Organization |||| Organizations',
        fields: {
          id: 'ID',
          subscriptionId: 'Subscription',
          publicName: "Public Name",
          wallet: "Wallet",
          logoUrl: "Logo URL",
          backgroundColor: "Background color",
          foregroundColor: "Foreground color",
          footerHtml: "Footer Html",
          createdAt: "Created at",
          fullName: "Admin's full name",
          adminFullName: "Admin's full name",
          emailAddress: "Admin's email address",
          adminEmail: "Admin's email address",
          vidconnectClientId: "Vidconnect client ID",
          vidconnectClientSecret: "Vidconnect client secret",
          vidconnectClientSecretUpdate: "Vidconnect client secret",
          multiwallet: 'Multiwallet',
          orgIdDuplicate: "Duplicate Organization's Resources",
          webCallbacksUrl: 'Web callbacks URL',
          wallets: {
            VIDWALLET: "Vidwallet",
            ALTME: "Altme"
          },
          idEq: 'By ID:',
          publicNameLike: "By Public Name:",
          nameLike: 'By Name:',
          didLike: 'By Did:',
          labelLike: 'By Label:',
          pointerLike: 'By Pointer:',
          filterLike: 'By Filter:',
          valueLike: 'By Value:',
          helperLike: 'By Helper:',
          archivedAtIsSet: 'Archived?',
          orgIdEq: 'By Organization:',
          eventLike: 'By Event:',
          contributorLike: 'By Contributor:',
          organizerLike: 'By Organizer:',
          sponsorLike: 'By Sponsor:',
          locationLike: 'By Location:',
          eventIdEq: 'By Event:',
        },
        loading: 'Loading...',
        archivedAtIsSetAll: 'All',
        archivedAtIsSetTrue: 'Only archived',
        archivedAtIsSetFalse: 'Only not archived',
      },
      Person: {
        name: 'Person |||| Persons',
        many: 'Persons',
        fields: {
          id: 'ID',
          orgId: 'Organization',
          emailAddress: 'Email',
          fullname: 'FullName',
          admin: 'is Admin?',
          lang: 'Language',
          langs: {
            EN: 'English',
            ES: 'Spanish',
            CA: 'Catalan',
            EU: 'Euskera',
            FR: 'French',
            IT: 'Italian',
            GL: 'Gallego',
            PT: 'Portuguese'
          },
          roles: {
            ADMIN: 'Admin',
            MANAGER: 'Manager',
            AUDITOR: 'Auditor',
            OPERATOR: 'Operator',
            AGENT: 'Agent',
            REVOKED: 'Revoked'
          },
          registrationDate: 'Registration Date',
          not_accepted_yet: "Not accepted. Click here.",
          isTermsAccepted: "Terms Accepted?",
          termsUrl: "Terms Acceptance",
          already_accepted: "Terms were accepted",
          isAdmin: "Is Admin",
          maybeRole: "Role",
          role: "Role",
          idEq: 'By ID:',
          orgIdEq: 'By Organization:',
        }
      },
      EmailAddress: {
        name: 'Email |||| Emails',
        fields: {
          address: 'Address',
          personId: 'Person',
          createdAt: 'Created at',
          role: 'Role',
          orgId: 'Organization',
          addressLike: 'By Address:',
          personIdEq: 'By Person:',
          orgIdEq: 'By Organization:',
        },
      },
      CredentialClaim: {
        name: 'Credential Claim |||| Credential Claims',
        many: 'Credential Claims',
        fields: {
          address: 'Address',
          participantId: 'Participant',
          uuid: 'UUID',
          issued: 'Issued?',
          revoked: 'Revoked?',
          orgId: 'Organization',
          orgIdEq: 'By Organization:',
          eventIdEq: 'By Event:',
          participantIdEq: 'By Participant ID:',
        },
      },
      Session: {
        name: 'Session |||| Sessions',
        fields: {
          id: 'Key',
          personId: 'Person',
          orgId: 'Organization',
          idLike: 'By Key:',
          personIdEq: 'By Person:',
          orgIdEq: 'By Organization:',
        },
      },
      OneTimeLogin: {
        name: 'One Time Login |||| One Time Logins',
        fields: {
          id: 'Nª',
          orgId: 'Organization',
          personId: 'Person',
          url: 'URL',
          idEq: 'By Nª:',
          orgIdEq: 'By Organization:',
          personIdEq: 'By Person:',
        },
      },
      OrgDeletion: {
        name: 'Organization Deletion |||| Organization Deletions',
        create: 'Request a Organization Deletion',
        edit: 'Cancel Organization Deletion',
        fields: {
          id: 'Nº',
          orgId: 'Organization',
          storyId: 'Story',
          reason: 'Reason',
          reasons: {
            USER_REQUEST: 'User Request',
            MANAGEMENT_DECISION: 'Management Decision',
            INACTIVITY: 'Inactivity',
          },
          description: 'Description',
          completed: 'Completed',
          approvingAdminUser: 'Approving Admin User',
          startedAt: 'Started At',
          idEq: 'By Nº:',
          orgIdEq: 'By Organization:',
          storyIdEq: 'By Story:',
          reasonEq: ' By Reason:',
          completedEq: 'By Completed:',
        },
      },
      Participant: {
        name: 'Participant |||| Participants',
        many: 'Participants',
        fields: {
          id: 'ID',
          orgId: 'Organization',
          emailAddress: 'Email Address',
          firstName: 'First Name',
          lastName: 'Last Name',
          company: 'Company',
          position: 'Position',
          lang: 'Language',
          langs: {
            EN: 'English',
            ES: 'Spanish',
            CA: 'Catalan',
            EU: 'Euskera',
            FR: 'French',
            IT: 'Italian',
            GL: 'Gallego',
            PT: 'Portuguese'
          },
          typeOfVisitor: 'Type of Visitor',
          typeOfVisitors: {
            ATTENDEE: 'Attendee',
            SPEAKER: 'Speaker',
            VIP: 'VIP',
            STAFF: 'Staff'
          },
          additionalData: 'Additional Data',
          registrationDate: 'Registration Date',
          orgIdEq: 'By Organization:',
          eventIdEq: 'By Event:',
          emailAddressLike: 'By Email Address:',
          lastNameLike: 'By Last Name:',
          companyLike: 'By Company:',
          attendedEq: 'Did they attend?'
        },
        seeParticipants: "See participants",
        downloadValues: "Download participants list",
        uploadFromFile: "Upload from a file",
        selectManually: "Or enter manually",  
        createByValues: "Create by values", 
        errorUpdating: "Error updating the attribute list.",
        mark_attended: {
          call_to_action: "Mark",
          title: "Are you sure you want to mark as attended?",
          content: "This participant will be marked as having attended the event"
        }
      },
      Event: {
        name: 'Event |||| Events',
        many: 'Events',
        fields: {
          id: 'ID',
          orgId: 'Organization',
          event: 'Event',
          about: 'About',
          description: 'Description',
          contributor: 'Contributor',
          organizer: 'Organizer',
          sponsor: 'Sponsor',
          location: 'Location',
          agenda: 'Agenda',
          startDate: 'Start Date',
          endDate: 'End Date',
          doorTime: 'Door Time',
          emailLogoUrl: 'Email Logo URL',
          emailTitleEs: 'Email Title (Spanish)',
          emailContentEs: 'Email Content (Spanish)',
          emailTitleEn: 'Email Title (English)',
          emailContentEn: 'Email Content (English)',
          emailTitleCa: 'Email Title (Catalan)',
          emailContentCa: 'Email Content (Catalan)',
          emailTitleEu: 'Email Title (Euskera)',
          emailContentEu: 'Email Content (Euskera)',
          emailTitleFr: 'Email Title (French)',
          emailContentFr: 'Email Content (French)',
          emailTitleIt: 'Email Title (Italian)',
          emailContentIt: 'Email Content (Italian)',
          emailTitleGl: 'Email Title (Gallego)',
          emailContentGl: 'Email Content (Gallego)',
          emailTitlePt: 'Email Title (Portuguese)',
          emailContentPt: 'Email Content (Portuguese)',
          orgIdEq: 'By Organization:',
          eventLike: 'By Event:',
          contributorLike: 'By Contributor:',
          organizerLike: 'By Organizer:',
          sponsorLike: 'By Sponsor:',
          locationLike: 'By Location:',
          emailAddressLike: 'By Email Address:',
          lastNameLike: 'By Name:',
          companyLike: 'By Company:',
          sentAtIsSet: 'By Sent At:',
          participantIdEq: 'By Participant ID:',
        },
      },
      OutgoingEmailMessage: {
        name: 'Email Message |||| Email Messages',
        many: 'Email Messages',
        fields: {
          id: 'Nº',
          orgId: 'Organization',
          personId: 'Person',
          emailAddressId: 'Email Address',
          sent: 'Sent?',
          sentAt: 'Sent at',
          kind: 'Kind',
          kinds: {
            REGISTRATION: 'Registration'
          },
          customMessage: 'Custom Message',
          extraData: 'Extra Data',
          idEq: 'By Nº:',
          orgIdEq: 'By Organization:',
          eventIdEq: 'By Event:',
          emailAddressIdEq: 'By Email Address:',
          sentAtIsSet: 'By Sent at',
        },
      },
      AdminUser: {
        name: 'Admin User |||| Admin Users',
        create: 'Create an Admin User',
        fields: {
          id: 'ID',
          username: 'Username',
          password: 'Password',
          otpSeed: 'OTP Seed',
          otp: 'OTP',
          role: 'Role',
          createdAt: 'Created At',
          usernameLike: 'By Username:',
          roleEq: 'By Role:',
          roles: {
            admin: 'Admin',
            superadmin: 'SuperAdmin',
          },
        },
      },
      ChangePassword: {
        name: 'Change Password',
        password: 'Password',
        newPassword: 'New Password',
        reEnterNewPassword: 'Re-enter new password',
        otp: 'OTP',
        confirmationFailed: 'Passwords must match',
      },
      actions: {
        createTemplate: 'Create Template',
        requestOrgDeletion: 'Request Org Deletion',
        cancelOrgDelete: 'Cancel Deletion',
        orgDeletionMessage: 'Be careful, once you request to delete an organization. The organization will not be able to access their own data.',
        orgDeletionCancelMessage: 'You are about to cancel the request to delete the organization, making all their data available again.',
        physicalDeletionConfirm: 'Be careful, you are about to permanently delete all data for this organization.',
        physicalDeletionCompleted: 'Physical deletion completed.',
        physicalDelete: 'Physical Delete',
        updated: 'Element updated',
        created: 'Element created',
        changePassword: 'Change Password',
        loading: 'Loading...',
        copy_to_clipboard: 'Link copied to clipboard',
        click_here: 'Click here to visit',
        details: 'Details',
        errorUpdatingResource: "Error: invalid fields for %{resource}.",
        errorInvalidFieldsResource: "Error: invalid fields.",
        createOne: "Create one",
        createFromFile: "or create many from a file",
        upload: "Upload CSV",
      },
    },
    patch: {
      auth: {
        sign_in: "Iniciar sesión",
        auth_check_error: "Por favor inicie sesión para continuar"
      },
      action: {
        unselect: "Deseleccionar"
      },
      navigation: {
        skip_nav: "Saltar al contenido",
        page_rows_per_page: "Filas por página:"
      }
    }
  }
};
