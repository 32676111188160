import { List, Datagrid, ShowButton, TextField, Show, NumberField, Create, SimpleForm, useSafeSetState, SimpleFormIterator,
         SimpleShowLayout, required, TextInput, useTranslate, useRedirect, useDataProvider, email, ArrayInput,
         DateTimeInput,
         Edit,
         EditButton,
         FunctionField,
         Button,
         ReferenceField,
         useNotify
} from 'react-admin'
import { Button as MuiButton } from '@mui/material';
import { PaginationDefault, defaultSort, downloadCSV } from "../components/utils";
import { PermIdentity, Download } from '@mui/icons-material';
import FilterTextInput from "../components/filter_textinput";
import { TopToolbarWithCreateButton } from "../components/top_toolbars";
import ParsedDateTextField from "../components/parsed_date_textfield";
import MyUrlField from "../components/my_url_field";
import { OnlySaveToolbar } from "../components/bottom_toolbars";
import OrgTextField from '../components/org_textfield';
import SelectOrgs from '../components/select_orgs';
import SelectLang from '../components/select_lang';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom'; 
import Loading from '../components/loading';
import { TabbedShowLayout } from 'react-admin';
import { Tab } from 'react-admin';
import { participantGrid } from "./participant";
import { participantFilters } from "./participant";
import { outgoingEmailMessageFilters, outgoingEmailMessageGrid } from "./outgoing_email_message";
import { credentialClaimFilters, credentialClaimGrid } from "./credential_claim";
import { FilterForm } from 'react-admin';
import { ReferenceManyField } from 'react-admin';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
export const EventIcon = PermIdentity;

const eventFilters = [
  <FilterTextInput source="eventLike" alwaysOn/>,
  <FilterTextInput source="contributorLike" alwaysOn/>,
  <FilterTextInput source="organizerLike" alwaysOn />,
  <FilterTextInput source="sponsorLike" alwaysOn />,
  <FilterTextInput source="locationLike" alwaysOn />,
];

function EventList() {

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={eventFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbarWithCreateButton />}
    >
      {eventGrid}
    </List>
  );
}

const eventGrid = 
  <Datagrid bulkActionButtons={false}>
    <TextField source='id' />
    <ReferenceField source="orgId" reference="Org" link="show">
      <OrgTextField source="id" />
    </ReferenceField>
    <TextField source="event" />
    <TextField source="contributor" />
    <TextField source="organizer" />
    <TextField source="sponsor" />
    <TextField source="location" />
    <ParsedDateTextField source="startDate" />
    <ShowButton />
    <EditButton />
  </Datagrid>

function EventShow(){
  const translate = useTranslate();
  const [participantFilter, setParticipantFilter] = useSafeSetState({});
  const [outgoingEmailMessageFilter, setOutgoingEmailMessageFilter] = useSafeSetState({});
  const [credentialClaimFilter, setCredentialClaimFilter] = useSafeSetState({});
  const redirect = useRedirect();
  const location = useLocation();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    if (location.pathname.match(/\/show$/) || location.pathname.match(/\/show#$/)) {
      redirect(`${location.pathname}/details`);
    } 
  }, [location]);


  const getResource = async (eventId, resource, perPage) => {
    return await dataProvider.getList(resource, {
      sort: {field: "attended", order: "DESC"}, pagination: { page: 1, perPage }, filter: {eventIdEq: eventId}
    });
  }

  const handleDownloadValues = async (eventId, resource, fields) => {
    try {
      const {total} = await getResource(eventId, resource, 1);
      const {data} = await getResource(eventId, resource, total);
      const finalParticipants = _.map(data, a => _.pick(a, fields));
      downloadCSV(finalParticipants, `${resource}_values`);
    } catch (e) {
      notify('admin.errors.default', {type: 'warning'});
    }
  };  

  return (
    <Show>
      <TabbedShowLayout syncWithLocation={true}>
      <Tab label="resources.actions.details" path="details">
        <NumberField source='id' />
        <ReferenceField source="orgId" reference="Org" link="show">
          <OrgTextField source="id" />
        </ReferenceField>
        <TextField source="event" />
        <TextField source="description" />
        <TextField source="contributor" />
        <TextField source="organizer" />
        <TextField source="sponsor" />
        <TextField source="location" />
        <MyUrlField source="agenda" />
        <ParsedDateTextField source="startDate" />
        <ParsedDateTextField source="endDate" />
        <ParsedDateTextField source="doorTime" />
        <ParsedDateTextField source="expirationDate" />
        <FunctionField render={ record => {
          return <Button
            href={`#/Participant?displayedFilters={"eventIdEq":true}&filter={"eventIdEq":${record.id}}`}
            label={translate("resources.Participant.seeParticipants", {smart_count: 2})}
          >
          <PermIdentity />
        </Button>
        }} />
        <FunctionFieldForDownload
          id="download-participants"
          handleDownloadValues={handleDownloadValues}
          resource="Participant"
          fields={['firstName', 'lastName', 'emailAddress', 'company', 'position', 'attended', 'typeOfVisitor', 'lang', 'additionalData']}
        />
        <TextField source="emailLogoUrl" />
        <TextField source="emailTitleEs" />
        <TextField source="emailContentEs" />
        <TextField source="emailTitleEn" />
        <TextField source="emailContentEn" />
        <TextField source="emailTitleCa" />
        <TextField source="emailContentCa" />
        <TextField source="emailTitleEu" />
        <TextField source="emailContentEu" />
        <TextField source="emailTitleFr" />
        <TextField source="emailContentFr" />
        <TextField source="emailTitleIt" />
        <TextField source="emailContentIt" />
        <TextField source="emailTitleGl" />
        <TextField source="emailContentGl" />
        <TextField source="emailTitlePt" />
        <TextField source="emailContentPt" />
        </Tab>

        <Tab label="resources.Participant.many" path="participants">
          <div className="nested-resource" >
            <ReferenceManyField reference="Participant" target="eventIdEq" label=""
              sort={defaultSort}
              filter={{ ...participantFilter, archivedAtIsSet: false }}
              perPage={20}
              pagination={<PaginationDefault />}
            >
              <FilterForm
                filters={participantFilters}
                onChange={(e: any) => { setParticipantFilter(f => ({...f, [e.target.name]: e.target.value}))}}
              />
              {participantGrid}
            </ReferenceManyField>
          </div>
        </Tab>
        <Tab label="resources.OutgoingEmailMessage.many" path="outgoingEmailMessage">
          <div className="nested-resource" >
            <ReferenceManyField reference="OutgoingEmailMessage" target="eventIdEq" label=""
              sort={defaultSort}
              filter={{ ...outgoingEmailMessageFilter }}
              perPage={20}
              pagination={<PaginationDefault />}
            >
              <FilterForm
                filters={outgoingEmailMessageFilters}
                onChange={(e: any) => { setOutgoingEmailMessageFilter(f => ({...f, [e.target.name]: e.target.value}))}}
              />
              {outgoingEmailMessageGrid}
            </ReferenceManyField>
          </div>
        </Tab>
        <Tab label="resources.CredentialClaim.many" path="credentialClaim">
          <div className="nested-resource" >
            <ReferenceManyField reference="CredentialClaim" target="eventIdEq" label=""
              sort={defaultSort}
              filter={{ ...credentialClaimFilter }}
              perPage={20}
              pagination={<PaginationDefault />}
            >
              <FilterForm
                filters={credentialClaimFilters}
                onChange={(e: any) => { setCredentialClaimFilter(f => ({...f, [e.target.name]: e.target.value}))}}
              />
              {credentialClaimGrid}
            </ReferenceManyField>
          </div>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}


const FunctionFieldForDownload = ({id, handleDownloadValues, resource, fields}) => {
  const translate = useTranslate();
  return <FunctionField render={record => <MuiButton
      size="small"
      startIcon={<Download />}
      id={id}
      onClick={() => handleDownloadValues(record.id, resource, fields)}
    >
      {translate(`resources.${resource}.downloadValues`)}
    </MuiButton>
    }
  />
}

const EventCreate = () => {
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  
  const onSubmit = async values => {
    let emailValues = getEmailsVariables(values);
    let finalValues = { ...values, ...emailValues };
    await dataProvider.create("Event", { data: { ...finalValues } });
    redirect("/Event");
  }

  return (
    <Create resource="Event" redirect="show">
      <SimpleForm onSubmit={onSubmit}>
        <SelectOrgs source="orgId" />
        <TextInput source="event" validate={required()} autoComplete="off" />
        <TextInput source="description" validate={required()} autoComplete="off" />
        <TextInput source="contributor" validate={required()} autoComplete="off" />
        <TextInput source="organizer" validate={required()} autoComplete="off" />
        <TextInput source="sponsor" validate={required()} autoComplete="off" />
        <TextInput source="location" validate={required()} autoComplete="off" />
        <TextInput source="agenda" validate={required()} autoComplete="off" />
        <DateTimeInput source="startDate" validate={required()} parse={parseDate}/>
        <DateTimeInput source="endDate" validate={required()} parse={parseDate} />
        <DateTimeInput source="doorTime" validate={required()} parse={parseDate} />
        <DateTimeInput source="expirationDate" validate={required()} parse={parseDate} />
        <TextInput source="emailLogoUrl" validate={required()} autoComplete="off" />

        <ArrayInput source="email" validate={required()}>
          <SimpleFormIterator fullWidth disableReordering inline>
            <SelectLang source="lang" />
            <TextInput source="emailTitle" />
            <TextInput source="emailContent" fullWidth multiline minRows={10} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
}

const parseDate = (date) => (date ? new Date(date).toISOString() : null);


const EventTitle = ({ record }: {record?: any}) => {
  return <span>Org {record ? `"${record.about || record.id}"` : ''}</span>;
};

const EventEdit = () => {
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const { id } = useParams();
  const [record, setRecord] = useSafeSetState(null);

  const onSubmit = async values => {
    let emailValues = getEmailsVariables(values);
    let finalValues = { ...values, ...emailValues };
    await dataProvider.update("Event", { id, previousData: {}, data: { ...finalValues, id } });
    redirect("/Event");
  }

  useEffect(() => {
    const fetchLangs = async () => {
      const { data } = await dataProvider.getOne('Event', { id });
      const emails = [
        { lang: 'ES', emailTitle: data.emailTitleEs, emailContent: data.emailContentEs },
        { lang: 'EN', emailTitle: data.emailTitleEn, emailContent: data.emailContentEn },
        { lang: 'CA', emailTitle: data.emailTitleCa, emailContent: data.emailContentCa },
        { lang: 'EU', emailTitle: data.emailTitleEu, emailContent: data.emailContentEu },
        { lang: 'FR', emailTitle: data.emailTitleFr, emailContent: data.emailContentFr },
        { lang: 'IT', emailTitle: data.emailTitleIt, emailContent: data.emailContentIt },
        { lang: 'GL', emailTitle: data.emailTitleGl, emailContent: data.emailContentGl },
        { lang: 'PT', emailTitle: data.emailTitlePt, emailContent: data.emailContentPt }
      ].filter(item => item.emailTitle && item.emailContent);
      setRecord({ ...data, email: emails });
    };

    fetchLangs();
  }, [dataProvider, id, setRecord]);

  if (!record) return <Loading />;

  return (
    <Edit title={<EventTitle />} redirect="show">
      <SimpleForm toolbar={<OnlySaveToolbar />} warnWhenUnsavedChanges onSubmit={onSubmit} defaultValues={record}>
        <SelectOrgs source="orgId" />
        <TextInput source="event" validate={required()} autoComplete="off" />
        <TextInput source="description" validate={required()} autoComplete="off" />
        <TextInput source="contributor" validate={required()} autoComplete="off" />
        <TextInput source="organizer" validate={required()} autoComplete="off" />
        <TextInput source="sponsor" validate={required()} autoComplete="off" />
        <TextInput source="location" validate={required()} autoComplete="off" />
        <TextInput source="agenda" validate={required()} autoComplete="off" />
        <DateTimeInput source="startDate" validate={required()} parse={parseDate}/>
        <DateTimeInput source="endDate" validate={required()} parse={parseDate} />
        <DateTimeInput source="doorTime" validate={required()} parse={parseDate} />
        <DateTimeInput source="expirationDate" validate={required()} parse={parseDate} />
        <TextInput source="emailLogoUrl" validate={required()} autoComplete="off" />
       
        <ArrayInput source="email" validate={required()}>
          <SimpleFormIterator fullWidth disableReordering inline>
            <SelectLang source="lang" />
            <TextInput source="emailTitle" />
            <TextInput source="emailContent" fullWidth multiline minRows={10} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
};

const getEmailsVariables = values => {
  let emails = {
    emailTitleEs: null, emailContentEs: null, emailTitleEn: null, emailContentEn: null,
    emailTitleCa: null, emailContentCa: null, emailTitleEu: null, emailContentEu: null,
    emailTitleFr: null, emailContentFr: null, emailTitleIt: null, emailContentIt: null,
    emailTitleGl: null, emailContentGl: null, emailTitlePt: null, emailContentPt: null,
  }

  const transformedEmails = values.email.reduce((acc, v) => {
    const { lang } = v;
    if (lang === 'ES') {
      acc.emailTitleEs = v.emailTitle;
      acc.emailContentEs = v.emailContent;
    } else if (lang === 'EN') {
      acc.emailTitleEn = v.emailTitle;
      acc.emailContentEn = v.emailContent;
    } else if (lang === 'CA') {
      acc.emailTitleCa = v.emailTitle;
      acc.emailContentCa = v.emailContent;
    } else if (lang === 'EU') {
      acc.emailTitleEu = v.emailTitle;
      acc.emailContentEu = v.emailContent;
    } else if (lang === 'FR') {
      acc.emailTitleFr = v.emailTitle;
      acc.emailContentFr = v.emailContent;
    } else if (lang === 'IT') {
      acc.emailTitleIt = v.emailTitle;
      acc.emailContentIt = v.emailContent;
    } else if (lang === 'GL') {
      acc.emailTitleGl = v.emailTitle;
      acc.emailContentGl = v.emailContent;
    } else if (lang === 'PT') {
      acc.emailTitlePt = v.emailTitle;
      acc.emailContentPt = v.emailContent;
    }
    return acc;
  }, {});

  return { ...emails, ...transformedEmails };
}

export {EventList, EventShow, EventCreate, EventEdit, eventGrid, eventFilters};
