import { SelectInput, useGetList, useTranslate } from 'react-admin';
import type { Validator } from 'react-admin';

interface SelectTemplatesInterface {
  source: string,
  validate?: Validator | Validator[],
  alwaysOn?: boolean,
}

const SelectOrgs = ({source, validate, alwaysOn}: SelectTemplatesInterface) => {
  const translate = useTranslate();
  const loadingValue = [{id: false, name: translate("resources.Org.loading")}];
  const { data: choices, isLoading } = useGetList(
    "Org",
    {
      pagination: { page: 1, perPage: 100 },
      sort: {field: 'id', order: 'ASC'},
      filter: {}
    },
  );

  const optionRenderer = choice => choice.publicName ? `${choice.id} - ${choice.publicName}` : choice.id;

  return(
  <SelectInput
    source={source}
    validate={validate}
    choices={ isLoading ? loadingValue : choices }
    optionText={optionRenderer}
    translateChoice={false}
    alwaysOn={alwaysOn}
    sx={{minWidth: "20em", maxWidth: "25em"}}
  />
)};

export default SelectOrgs;